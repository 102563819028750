import React, { useEffect } from 'react';
import { isExpired, decodeToken } from "react-jwt";
const FacebookLoginCustomButton = ({ onLoginSuccess, onLoginFailure }) => {
    const responseFacebook = (response) => {
        // Handle the response from Facebook login
        if (response.accessToken) {
            onLoginSuccess(response.accessToken);
        } else {
            onLoginFailure(response);
        }
    };
    //https://www.facebook.com/dialog/oauth/?client_id=YOUR_APP_ID&redirect_uri=YOUR_REDIRECT_URL&state=YOUR_STATE_VALUE&scope=COMMA_SEPARATED_LIST_OF_PERMISSION_NAMES
    // useEffect(() => {
    //     // Load the Facebook SDK asynchronously
    //     window.fbAsyncInit = function () {
    //         window.FB.init({
    //             appId: '910047126729529',
    //             cookie: true,
    //             xfbml: true,
    //             version: 'v14.0'
    //         });

    //         // Manually trigger Facebook login popup on component mount
    //         window.FB.login(
    //             response => {
    //                 if (response.authResponse) {
    //                     responseFacebook(response);
    //                     console.log("Data Received", response.authResponse)
    //                     const decoded_Token = decodeToken(response.authResponse);
    //                     console.log("Token Data ", decoded_Token)

    //                     window.FB.api('/me', { fields: 'name,email,picture' }, (response) => {
    //                         if (response && !response.error) {
    //                             const userData = {
    //                                 name: response.name,
    //                                 email: response.email || '', // Handle case when email is not available
    //                                 picture: response.picture?.data?.url
    //                             };
    //                             console.log(userData);
    //                         } else {
    //                             console.log(response.error);
    //                         }
    //                     });
    //                 } else {
    //                     console.log("Token Data fail ",)
    //                 }
    //             },
    //             { scope: 'public_profile,email' }
    //         );

    //     };

    //     (function (d, s, id) {
    //         var js, fjs = d.getElementsByTagName(s)[0];
    //         if (d.getElementById(id)) return;
    //         js = d.createElement(s); js.id = id;
    //         js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0&appId=YOUR_FACEBOOK_APP_ID&autoLogAppEvents=1';
    //         fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'facebook-jssdk'));
    // }, []); // Empty dependency array to trigger only once on mount

    return null; // Since we don't render anything, return null
};

export default FacebookLoginCustomButton;