import { useEffect, useState } from "react";
import FacebookLoginCustomButton from "./components/FacebookLoginButton";
import { isExpired, decodeToken } from "react-jwt";
import "./App.css"
var serverUrl = "https://access.abhiwandemos.com"
//serverUrl = "http://localhost:3000"


const discordURL = `https://discord.com/oauth2/authorize?client_id=1243511987443470428&response_type=token&redirect_uri=${serverUrl}&scope=email+identify`
//OLD ONE const fbId = 2018186218574100;
const fbId = 639712454852655;
function App() {
  const [mainURL, setMainUrl] = useState('');
  const [toCopyText, setToCopyText] = useState('');
  const [copyToggle, setCopyToggle] = useState(false);
  useEffect(() => {

    //  window.addEventListener('FBLoaded', () => {
    //testLog://deepTest
    //abhiwandemos.com/?login=google&open=testLog://deepTest
    //let mainURl = new URLSearchParams(window.location.hash).get('access_token');
    let mainURl = window.location.href;
    const hash = mainURl.substring(mainURl.indexOf('#') + 1);
    let mainResult = hash.split('&')
    console.log("logmainURlinType..>", mainResult)
    let result = mainResult[0].split('=')
    var invite = result[1]
    console.log("result", result[1]);
    let loginType = new URLSearchParams(window.location.search).get("login");
    let authType = new URLSearchParams(window.location.search).get("authType");
    console.log("result ", authType);
    let toOpenApp = new URLSearchParams(window.location.search).get("open");

    console.log('authType..>', authType)
    console.log('toOpenApp..>', toOpenApp)

    if (authType == 'google') {
      fetchGoogleProfile(invite)
      invite = null
    }
    if (sessionStorage.getItem('login') == 'discord') {
      var discordToken = mainResult[1].split('=')[1]
      console.log("discord >>", discordToken)
      fetchDiscordProfile(discordToken)
    }
    if (sessionStorage.getItem('login') == 'fb') {
      fetchFbAccessToken(result[1])
    }

    if (toOpenApp) {
      sessionStorage.removeItem('open')
      sessionStorage.setItem('open', toOpenApp)
    }
    if (loginType) {
      sessionStorage.removeItem('login')
      sessionStorage.setItem('login', loginType)
    }
    console.log("invite..>", invite)
    console.log("loginType..>", loginType)
    if (loginType == 'fb') {
      window.location.href = `https://www.facebook.com/dialog/oauth/?client_id=${fbId}&redirect_uri=${serverUrl}&response_type=token&scope=public_profile,email`

    } else if (loginType == 'google') {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=1069261068415-mv4al9v4g76h0ea6qv91qj05popnpscv.apps.googleusercontent.com&redirect_uri=${serverUrl}?authType=google&response_type=token&scope=email%20profile`
    }
    else if (loginType == 'discord') {
      window.location.href = discordURL
    }
    // if (invite) {
    //   console.log("invite.0000.>", invite)
    //   fetchFbAccessToken(invite)
    // } else {

    // }
    // })
  }, [])

  function fetchFbAccessToken(token) {

    console.log("Fetching access token>", token)
    fetch(`https://graph.facebook.com/v13.0/me?fields=email,name,picture`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        console.log("checkWallet:>>", json);

        const toSendApp = {
          accessToken: token,
          name: json.name,
          photo: json.picture.data,
          email: json.email
        }
        OpenApplication(toSendApp);

        // const toSendJson = JSON.stringify(toSendApp);
        // const instaTest = 'instagram://user?username=iam__optimus'
        // console.log("Final open JSON>>", toSendJson)
        // var toOpen = sessionStorage.getItem('open')
        // toOpen = toOpen.replace('DaTa', toSendJson);
        // setMainUrl(toOpen);
        // window.location.href = toOpen
      });
  }

  function fetchGoogleProfile(accessToken) {
    fetch('https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses,photos', {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response containing the user's profile information
        console.log(data);
        // Extract the desired information from the response, such as name and email
        const toSendApp = {
          accessToken: accessToken,
          name: data.names[0],
          photo: data.photos[0],
          email: data.emailAddresses[0]
        }
        OpenApplication(toSendApp);

        // const toSendJson = JSON.stringify(toSendApp);
        // const instaTest = 'instagram://user?username=iam__optimus'
        // console.log("Final open JSON>>", toSendJson)
        // var toOpen = sessionStorage.getItem('open')
        // toOpen = toOpen.replace('DaTa', toSendJson);
        // setMainUrl(toOpen);
        // window.location.href = toOpen;
        // Use the retrieved profile information as needed
      })
      .catch(error => {
        // Handle error if the request fails
        console.error('Error fetching profile:', error);
      });
  }

  function fetchDiscordProfile(accessToken) {
    fetch('https://discord.com/api/v9/users/@me', {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response containing the user's profile information
        console.log('discordData', data);
        // Extract the desired information from the response, such as name and email
        const toSendApp = {
          accessToken: accessToken,
          name: data.username,
          photo: data.avatar,
          email: data.email
        }
        OpenApplication(toSendApp);

      })
      .catch(error => {
        // Handle error if the request fails
        console.error('Error fetching profile:', error);
      });
  }
  //https://www.facebook.com/dialog/oauth/?client_id=c&redirect_uri=localhost:3000&scope=name
  const handleLoginFailure = (error) => {
    // Handle login failure
    console.log('Login failure!', error);
  };


  const handleOpenApp = () => {
    console.log('Opening APP!');
  };

  function OpenApplication(data) {
    const toSendJson = JSON.stringify(data);
    console.log("Final open JSON>>", toSendJson)
    var toOpen = sessionStorage.getItem('open')
    console.log("TO Open Inclur ", toOpen)
    var isEditor = false;
    if (!toOpen) {

      return
    }
    if (toOpen.includes('http') || toOpen.includes('https') || toOpen.includes("www")) {
      toOpen = toOpen + ('?DaTa=' + toSendJson);

    }
    else if (toOpen.includes('Editor')) {
      isEditor = true;
      toOpen = toOpen.replace('DaTa', toSendJson);
      console.log("Using Editor", toSendJson)
      setToCopyText(toSendJson);
      setCopyToggle(true);
    }
    else {

      toOpen = toOpen + ('?DaTa=' + toSendJson);
    }
    var finalDataToSet = toOpen + '\n' + toSendJson;
    setMainUrl(finalDataToSet);
    if (!isEditor) {

      console.log("OPening Data ", toOpen)
      window.location.href = toOpen;
    }
  }

  const copy = async () => {
    await navigator.clipboard.writeText(toCopyText);
    console.log('Text copied to clipboard', toCopyText);
    window.close();
  }
  return (
    <div>
      {/* <button onClick={handleOpenApp}>
        OpernApp
      </button> */}
      {copyToggle == true ? <div className="container">
        <h1 className="header">Copy Data and back to Editor!</h1>
        <button className="copy-button" onClick={copy}>Copy to Clipboard</button>
      </div> : null}

      {mainURL}
    </div>
  );



}

export default App;
